import React from "react";
import {Route, Routes} from "react-router-dom";
import {LoadingOutlined} from "@ant-design/icons";
import {Spin} from "antd";
import styles from "./routingContainer.module.less";
import * as Pages from "./pages";

const mainPage = {route: "/", name: "Strona główna"};
const users = {route: "/users", name: "Użytkownicy", breadcrumbEntries: [mainPage, {name: "Użytkownicy"}]};
const managerClients = {
    route: "/managerClients",
    name: "Twoi klienci",
    breadcrumbEntries: [mainPage, {name: "Twoi klienci"}]
};
const yourProfile = {
    route: "/yourProfile",
    name: "Twoje dane",
    breadcrumbEntries: [mainPage, {name: "Twoje dane"}]
};
const news = {route: "/newsAdmin", name: "Newsy", breadcrumbEntries: [mainPage, {name: "Newsy"}]};
const userNews = {route: "/news", name: "Aktualności", breadcrumbEntries: [mainPage, {name: "Aktualności"}]};
const managerClientsCases = {
    route: "/managerClientsCases",
    name: "Sprawy twoich klientów",
    breadcrumbEntries: [mainPage, {name: "Sprawy twoich klientów"}]
};
const managerClientCase = {
    route: "/managerClients/:clientId/cases/:id",
    breadcrumbEntries: [mainPage, managerClientsCases, {name: "Szczegóły sprawy"}]
};
const allCases = {
    route: "/cases",
    name: "Wszystkie sprawy",
    breadcrumbEntries: [mainPage, {name: "Wszystkie sprawy"}]
};
const yourCases = {
    route: "/yourCases",
    name: "Twoje sprawy",
    breadcrumbEntries: [mainPage, {name: "Twoje sprawy"}]
};
const expertCases = {
    route: "/expertCases",
    name: "Prowadzone sprawy",
    breadcrumbEntries: [mainPage, {name: "Prowadzone sprawy"}]
};
const expertEmployees = {
    route: "/yourExpertEmployees",
    name: "Twoi pracownicy",
    breadcrumbEntries: [mainPage, {name: "Pracownicy"}]
};
const expertEmployeesCases = {
    route: "/yourExpertEmployees/cases",
    name: "Sprawy twoich pracowników",
    breadcrumbEntries: [mainPage, {name: "Sprawy twoich pracowników"}]
};
const people = {
    route: "/people",
    name: "Twoi managerowie",
    breadcrumbEntries: [mainPage, {name: "Twoi managerowie"}]
};

const calendar = {
    route: "/calendar",
    name: "Kalendarz",
    breadcrumbEntries: [mainPage, {name: "Kalendarz"}]
}
const organisationTable = {
    route: "/organisations",
    name: "Organizacje",
    breadcrumbEntries: [mainPage, {name: "Organizacje"}]
};

const caseDetails = {
    route: "/cases/:caseId",
    name: "Szczegóły sprawy",
    breadcrumbEntries: [mainPage, allCases, {name: "Szczegóły sprawy"}]
};

const usersProfile = {
    route: "/users/:userId",
    name: "Dane użytkownika",
    breadcrumbEntries: [mainPage, users, {name: "Dane użytkownika"}]
};

const kpis = {
    route: "/kpi",
    name: "KPI",
    breadcrumbEntries: [mainPage, { name: "KPI" }]
}

const marketingCampaignTable = {
    route: "/marketingCampaigns",
    name: "Kampanie marketingowe",
    breadcrumbEntries: [mainPage, { name: "Kampanie marketingowe" }]
}

const yourCaseDetails = {
    route: "/yourCases/:id",
    name: "Szczegóły sprawy",
    breadcrumbEntries: [mainPage, yourCases, {name: "Szczegóły sprawy"}]
};

const yourExpertEmployeesCasesDetails = {
    route: "/yourExpertEmployees/cases/:id",
    name: "Szczegóły sprawy",
    breadcrumbEntries: [mainPage, expertEmployeesCases, {name: "Szczegóły sprawy"}]
};

const expertCaseDetails = {
    route: "/expertCases/:id",
    name: "Szczegóły sprawy",
    breadcrumbEntries: [mainPage, expertCases, {name: "Szczegóły sprawy"}]
};
const managerClientCaseDetails = {
    route: "/managerClientsCases/:id",
    name: "Szczegóły sprawy",
    breadcrumbEntries: [mainPage, managerClientsCases, {name: "Szczegóły sprawy"}]
};
const kpiTaxGroup = {route: "/kpi/tax", name: "Księgowość", breadcrumbEntries: [mainPage, kpis, {name: "Księgowość"}]};     
const kpiLawGroup = {route: "/kpi/law", name: "Rekrutacja", breadcrumbEntries: [mainPage, kpis, {name: "Rekrutacja"}]};     
const kpiMarketingGroup = {route: "/kpi/marketing", name: "Marketing", breadcrumbEntries: [mainPage, kpis, {name: "Marketing"}]};     
const kpiExpertSettlementsGroup = {route: "/kpi/expertSettlements", name: "Rozliczenia ekspertów", breadcrumbEntries: [mainPage, kpis, {name: "Rozliczenia ekspertów"}]};     
const kpiRecruitmentGroup = {route: "/kpi/recruitment", name: "Rekrutacja", breadcrumbEntries: [mainPage, kpis, {name: "Rekrutacja"}]};    
const accountingTypeTable = {route: "/accountingTypes", name: "Rodzaje księgowości", breadcrumbEntries: [mainPage, {name: "Rodzaje księgowości"}]};
const caseInvoiceDetails = {
    route: "/cases/:caseId/invoice/:invoiceId",
    name: "Szczegóły sprawy",
    breadcrumbEntries: [mainPage, allCases, caseDetails, {name: "Szczegóły faktury"}]
};
const yourCaseInvoiceDetails = {
    route: "/yourCases/:caseId/invoice/:invoiceId",
    breadcrumbEntries: [mainPage, yourCases, { ...yourCaseDetails, route: yourCaseDetails.route.replace(":id", ":caseId") }, {name: "Szczegóły faktury"}]
};
const managerClientCaseInvoiceDetails = {
    route: "/managerClients/:clientId/cases/:caseId/invoice/:invoiceId",
    breadcrumbEntries: [mainPage, managerClientsCases, { ...managerClientCase, route: managerClientCase.route.replace(":id", ":caseId") }, {name: "Szczegóły faktury"}]
};
const yourExpertCaseInvoiceDetails = {
    route: "/yourExpertEmployees/cases/:caseId/invoice/:invoiceId",
    breadcrumbEntries: [mainPage, expertEmployeesCases, { ...yourExpertEmployeesCasesDetails, route: yourExpertEmployeesCasesDetails.route.replace(":id", ":caseId") }, {name: "Szczegóły faktury"}]
};
const expertCaseInvoiceDetails = {
    route: "/expertCases/:caseId/invoice/:invoiceId",
    breadcrumbEntries: [mainPage, expertCases, { ...expertCaseDetails, route: expertCaseDetails.route.replace(":id", ":caseId") }, {name: "Szczegóły faktury"}]
};
const managerCaseInvoiceDetails = {
    route: "/managerClientsCases/:caseId/invoice/:invoiceId",
    breadcrumbEntries: [mainPage, managerClientsCases, { ...managerClientCaseDetails, route: managerClientCaseDetails.route.replace(":id", ":caseId") }, {name: "Szczegóły faktury"}]
};
const patronClients = {
    route: "/patronClients",
    name: "Twoi klienci",
    breadcrumbEntries: [mainPage, {name: "Twoi klienci"}]
};
const patronClientsCases = {
    route: "/patronClientsCases",
    name: "Sprawy twoich klientów",
    breadcrumbEntries: [mainPage, {name: "Sprawy twoich klientów"}]
};

export const RoutingPaths = {
    login: {route: "/login"},
    register: {route: "/register"},
    forgotPassword: {route: "/forgotPassword"},
    forgotPasswordComplete: {route: "/forgotPasswordComplete"},
    activate: {route: "/activate"},
    confirmDetails: {route: "/confirmDetails"},
    editProfile: {route: "/editProfile", breadcrumbEntries: [mainPage, yourProfile, {name: "Edycja danych"}]},
    yourPatron: {route: "/yourAccountManager", breadcrumbEntries: [mainPage, {name: "Twój opiekun"}]},
    changePassword: {route: "/changePassword", breadcrumbEntries: [mainPage, yourProfile, {name: "Zmień hasło"}]},
    marketingCampaignTable,
    patronClientsExpertEmployees: {
        route: "/yourClientsExpertEmployees",
        breadcrumbEntries: [mainPage, {name: "Eksperci twoich klientów"}]
    },
    patronClientsExpertEmployeesDetails: {
        route: "/yourClientsExpertEmployees/:id",
        breadcrumbEntries: [mainPage, {name: "Eksperci twoich klientów"}]
    },
    patronClientsProfile: {
        route: "/patronClients/:id",
        breadcrumbEntries: [mainPage, patronClients, {name: "Dane klienta"}]
    },
    patronClientsCreate: {
        route: "/patronClients/create",
        breadcrumbEntries: [mainPage, patronClients, {name: "Dodaj nowego klienta"}]
    },
    patronClientsEdit: {
        route: "/patronClients/:id/edit",
        breadcrumbEntries: [mainPage, patronClients, {name: "Edycja danych klienta"}]
    },
    patronClientsCasesAdd: {
        route: "/patronClientsCases/create",
        breadcrumbEntries: [mainPage, patronClientsCases, {name: "Zgłoś sprawę"}]
    },
    patronClientCasesAdd: {
        route: "/patronClients/:clientId/cases/create",
        breadcrumbEntries: [mainPage, patronClientsCases, {name: "Zgłoś sprawę"}]
    },
    patronClientCase: {
        route: "/patronClients/:clientId/cases/:id",
        breadcrumbEntries: [mainPage, patronClientsCases, {name: "Szczegóły sprawy"}]
    },
    patronClientsCasesEdit: {
        route: "/patronClientsCases/:id/edit",
        breadcrumbEntries: [mainPage, patronClientsCases, {name: "Edytuj sprawę"}]
    },
    patronClientCaseDetails: {
        route: "/patronClientsCases/:id",
        breadcrumbEntries: [mainPage, patronClientsCases, {name: "Szczegóły sprawy"}]
    },
    patronClientsCases,
    patronClients,
    patronClientCases: { route: "/patronClients/:id/cases", breadcrumbEntries: [ mainPage, patronClients, { name: "Sprawy klienta" } ] },
    kpiEngine: {route: "/kpi/engine", breadcrumbEntries: [mainPage, kpis, {name: "Kampanie"}]},
    kpiSubscriptions: {route: "/kpi/subscriptions", breadcrumbEntries: [mainPage, kpis, {name: "Abonamenty"}]},
    kpiSalesman: {route: "/kpi/salesman", breadcrumbEntries: [mainPage, kpis, {name: "Sprzedawcy"}]},
    kpiInvestPitch: {route: "/kpi/investPitch", breadcrumbEntries: [mainPage, kpis, {name: "Ofertowanie invest"}]},
    kpiInvestInProgress: {route: "/kpi/investInProgress", breadcrumbEntries: [mainPage, kpis, {name: "Obsługa invest"}]},
    kpiOverdue: {route: "/kpi/overdue", breadcrumbEntries: [mainPage, kpis, {name: "Należności"}]},
    kpiTaxInProgress: {route: "/kpi/tax/inProgress", breadcrumbEntries: [mainPage, kpis, kpiTaxGroup, {name: "KPI Kontrakty w toku"}]},
    kpiTaxDone: {route: "/kpi/tax/done", breadcrumbEntries: [mainPage, kpis, kpiTaxGroup, {name: "KPI Kontrakty zakończone"}]},
    kpiTaxNew: {route: "/kpi/tax/new", breadcrumbEntries: [mainPage, kpis, kpiTaxGroup, {name: "KPI Ofertowanie księgowości"}]},
    kpiMarketingNew: {route: "/kpi/marketing/new", breadcrumbEntries: [mainPage, kpis, kpiMarketingGroup, {name: "KPI Marketing otwarte"}]},
    kpiMarketingInProgress: {route: "/kpi/marketing/inProgress", breadcrumbEntries: [mainPage, kpis, kpiMarketingGroup, {name: "KPI Marketing w toku"}]},
    kpiMarketingDone: {route: "/kpi/marketing/done", breadcrumbEntries: [mainPage, kpis, kpiMarketingGroup, {name: "KPI Marketing zamknięte"}]},
    kpiNewBusinessMeeting: {route: "/kpi/newBusinessMeeting", breadcrumbEntries: [mainPage, kpis, {name: "Spotkania New Business"}]},
    kpiServiceMeeting: {route: "/kpi/serviceMeeting", breadcrumbEntries: [mainPage, kpis, {name: "Spotkania Service"}]},
    kpiPurgatory: {route: "/kpi/purgatory", breadcrumbEntries: [mainPage, kpis, {name: "Czyściec"}]},
    kpiManagers: {route: "/kpi/managers", breadcrumbEntries: [mainPage, kpis, {name: "Współpraca z managerami"}]},
    kpiNewBusiness: {route: "/kpi/newBusiness", breadcrumbEntries: [mainPage, kpis, {name: "Call Center"}]},
    kpiRecruitment: {route: "/kpi/recruitment/table", breadcrumbEntries: [mainPage, kpis, kpiRecruitmentGroup, {name: "KPI Rekrutacja"}]},
    kpiChf: {route: "/kpi/law/chf", breadcrumbEntries: [mainPage, kpis, kpiLawGroup, {name: "KPI CHF"}]},
    kpiHell: {route: "/kpi/hell", breadcrumbEntries: [mainPage, kpis, {name: "Piekło"}]},
    kpiCompliance: {route: "/kpi/compliance", breadcrumbEntries: [mainPage, kpis, {name: "Compliance"}]},
    kpiInvestSales: {route: "/kpi/investSales", breadcrumbEntries: [mainPage, kpis, {name: "Sprzedaż Invest"}]},
    kpiClientActivity: {route: "/kpi/clientActivity", breadcrumbEntries: [mainPage, kpis, {name: "Aktywność klientów"}]},
    kpiLegal: {route: "/kpi/legal", breadcrumbEntries: [mainPage, kpis, {name: "Legal"}]},
    kpiLeads: {route: "/kpi/leads", breadcrumbEntries: [mainPage, kpis, {name: "Leady"}]},
    kpiTaxSettlements: {route: "/kpi/expertSettlements/tax", breadcrumbEntries: [mainPage, kpis, kpiExpertSettlementsGroup, {name: "KPI Eksperci Księgowość"}]},
    kpiLawSettlements: {route: "/kpi/expertSettlements/law", breadcrumbEntries: [mainPage, kpis, kpiExpertSettlementsGroup, {name: "KPI Eksperci Prawo"}]},
    kpiTaxGroup,
    kpiLawGroup,
    kpiRecruitmentGroup,
    kpiExpertSettlementsGroup,
    kpiMarketingGroup,
    leadSourceTable: {
        route: "/marketingCampaigns/leads",
        name: "Źródła leadów",
        breadcrumbEntries: [mainPage, marketingCampaignTable, { name: "Źródła leadów" }]
    },
    departmentTable: {
        route: "/departments",
        name: "Departamenty",
        breadcrumbEntries: [mainPage, { name: "Departamenty" }]
    },
    organisationTable,
    organisationUserTable: {
        route: "/organisations/:organisationId/users",
        breadcrumbEntries: [mainPage, organisationTable, {name: "Użytkownicy organizacji"}]
    },
    professions: {route: "/professions"},
    managerTitles: {route: "/managerTitles"},
    managerRanking: {
        route: "/rankings/manager",
        breadcrumbEntries: [mainPage, kpis, {name: "Ranking managerów"}]
    },
    departmentRanking: {
        route: "/rankings/department",
        breadcrumbEntries: [mainPage, kpis, {name: "Ranking departamentów"}]
    },
    overdueSettlements: {
        route: "/overdueSettlements",
        breadcrumbEntries: [mainPage, {name: "Windykacja"}]
    },
    clientRanking: {
        route: "/rankings/client",
        breadcrumbEntries: [mainPage, kpis, {name: "Ranking klientów"}]
    },
    expertRanking: {
        route: "/rankings/expert",
        breadcrumbEntries: [mainPage, kpis, {name: "Ranking ekspertów"}]
    },
    ambassadorRanking: {
        route: "/rankings/ambassador",
        breadcrumbEntries: [mainPage, kpis, {name: "Ranking ambasadorów"}]
    },
    expertEmployeesProfile: {
        route: "/expertEmployees/profile/:id",
        breadcrumbEntries: [mainPage, expertEmployees, {name: "Dane eksperta - pracownika"}]
    },
    expertEmployeesCreate: {
        route: "/expertEmployees/create",
        breadcrumbEntries: [mainPage, expertEmployees, {name: "Dodaj eksperta - pracownika"}]
    },
    yourExpertEmployeesCasesDetails,
    clientExpertEmployees: {
        route: "/clientExpertEmployees",
        breadcrumbEntries: [mainPage, {name: "Twoi eksperci"}]
    },
    clientExpertEmployeesDetails: {
        route: "/clientExpertEmployees/:id",
        breadcrumbEntries: [mainPage, {name: "Twój ekspert"}]
    },
    clientManagerDetails: {
        route: "/clientManager/:id",
        breadcrumbEntries: [mainPage, people, {name: "Twój manager"}]
    },
    createNews: {
        route: "/news/create",
        breadcrumbEntries: [mainPage, news, {name: "Dodaj newsa"}]
    },
    editNews: {
        route: "/news/edit/:id",
        breadcrumbEntries: [mainPage, news, {name: "Edycja newsa"}]
    },
    userNewsDetails: {
        route: "/news/:id",
        breadcrumbEntries: [mainPage, userNews, {name: ""}]
    },
    usersProfile: {
        route: "/users/:id",
        breadcrumbEntries: [mainPage, users, {name: "Dane użytkownika"}]
    },
    usersCreate: {
        route: "/users/create",
        breadcrumbEntries: [mainPage, users, {name: "Dodaj użytkownika"}]
    },
    usersEdit: {
        route: "/users/:id/edit",
        breadcrumbEntries: [mainPage, users, {name: "Edycja danych użytkownika"}]
    },
    managerClientsProfile: {
        route: "/managerClients/:id",
        breadcrumbEntries: [mainPage, managerClients, {name: "Dane klienta"}]
    },
    managerClientsCreate: {
        route: "/managerClients/create",
        breadcrumbEntries: [mainPage, managerClients, {name: "Dodaj nowego klienta"}]
    },
    managerClientsEdit: {
        route: "/managerClients/:id/edit",
        breadcrumbEntries: [mainPage, managerClients, {name: "Edycja danych klienta"}]
    },
    casesAdd: {
        route: "/cases/create",
        breadcrumbEntries: [mainPage, allCases, {name: "Zgłoś sprawę"}]
    },
    managerClientsCasesAdd: {
        route: "/managerClientsCases/create",
        breadcrumbEntries: [mainPage, managerClientsCases, {name: "Zgłoś sprawę"}]
    },
    clientCasesAdd: {
        route: "/users/:clientId/cases/create",
        breadcrumbEntries: [mainPage, users, {name: "Zgłoś sprawę"}]
    },
    managerClientCasesAdd: {
        route: "/managerClients/:clientId/cases/create",
        breadcrumbEntries: [mainPage, managerClientsCases, {name: "Zgłoś sprawę"}]
    },
    managerClientCase,
    casesEdit: {
        route: "/cases/edit/:id",
        breadcrumbEntries: [mainPage, allCases, {name: "Edytuj sprawę"}]
    },
    managerClientsCasesEdit: {
        route: "/managerClientsCases/:id/edit",
        breadcrumbEntries: [mainPage, managerClientsCases, {name: "Edytuj sprawę"}]
    },
    caseDetails: {
        route: "/cases/:id",
        breadcrumbEntries: [mainPage, allCases, {name: "Szczegóły sprawy"}]
    },
    yourCaseAdd: {
        route: "/yourCases/add",
        breadcrumbEntries: [mainPage, yourCases, {name: "Zgłoś sprawę"}]
    },
    yourCaseDetails,
    managerClientCaseDetails,
    expertCaseDetails,
    managerClientCases: {
        route: "/managerClients/:clientId/cases",
        breadcrumbEntries: [mainPage, managerClients, {name: "Sprawy klienta"}]
    },
    userCases: {
        route: "/users/:userId/cases",
        breadcrumbEntries: [mainPage, users, usersProfile, {name: "Sprawy klienta"}]
    },
    productAdd: {route: "/products/create", breadcrumbEntries: [mainPage, {name: "Rodzaje spraw"}]},
    productEdit: {route: "/products/:id/edit", breadcrumbEntries: [mainPage, {name: "Rodzaje spraw"}]},
    productTable: {route: "/products", breadcrumbEntries: [mainPage, {name: "Rodzaje spraw"}]},
    subscriptionDefinitionTable: {route: "/subscriptionDefiniftions", breadcrumbEntries: [mainPage, {name: "Subskrypcje"}]},
    accountingTypeTable,
    accountingTypeAdd: {route: "/accountingTypes/create", breadcrumbEntries: [mainPage, accountingTypeTable, {name: "Utwórz rodzaj księgowości"}]},
    accountingTypeEdit: {
        route: "/accountingTypes/:id/edit",
        breadcrumbEntries: [mainPage, accountingTypeTable, {name: "Edytuj rodzaj księgowości"}]
    },

    accountingAttachmentTypeAdd: {route: "/accountingAttachmentTypes/create", breadcrumbEntries: [mainPage, accountingTypeTable, {name: "Dodaj strukturę załączników"}]},
    accountingAttachmentTypeEdit: {
        route: "/accountingAttachmentTypes/:id/edit",
        breadcrumbEntries: [mainPage, accountingTypeTable, {name: "Edytuj strukturę załączników"}]
    },
    accountingAttachmentTypeTable: {route: "/accountingAttachmentTypes", breadcrumbEntries: [mainPage, {name: "Rodzaje księgowości"}]},

    milestoneEdit: {route: "/milestones/:id/edit", breadcrumbEntries: [mainPage, {name: "Rodzaje spraw"}]},
    settlementAdd: {route: "/settlement/create/:caseId", breadcrumbEntries: [mainPage, {name: "Rozliczenia"}]},
    settlementEdit: {route: "/settlement/:id/edit", breadcrumbEntries: [mainPage, {name: "Rozliczenia"}]},
    settlementTable: {route: "/settlements", breadcrumbEntries: [mainPage, {name: "Rozliczenia"}]},
    mySettlementsTable: {route: "/mySettlements", breadcrumbEntries: [mainPage, {name: "Twoje rozliczenia"}]},
    clientSettlementsTable: {route: "/clientSettlements", breadcrumbEntries: [mainPage, {name: "Twoje płatności"}]},
    incomeTable: {route: "/income", breadcrumbEntries: [mainPage, {name: "Twoje przychody"}]},
    managerAttachments: {route: "/tabela-premii", breadcrumbEntries: [mainPage, {name: "Dokumenty"}]},
    calendar,
    caseCalendar: {
        route: "/calendar/case/:caseId",
        breadcrumbEntries: [mainPage, allCases, caseDetails, {name: "Kalendarz sprawy"}]
    },
    caseInvoiceAddForm: {
        route: "/cases/:caseId/invoice",
        breadcrumbEntries: [mainPage, allCases, caseDetails, {name: "Nowa faktura"}]
    },
    caseCorrectionInvoiceAddForm: {
        route: "/cases/:caseId/invoice/:invoiceId/correction",
        breadcrumbEntries: [mainPage, allCases, caseDetails, caseInvoiceDetails, {name: "Nowa faktura korygująca"}]
    },
    caseInvoiceDetails,
    caseInvoiceEditForm: {
        route: "/cases/:caseId/invoice/:invoiceId/edit",
        breadcrumbEntries: [mainPage, allCases, caseDetails, {name: "Edytuj fakturę"}]
    },
    yourCaseInvoiceAddForm: {
        route: "/yourCases/:caseId/invoice",
        breadcrumbEntries: [mainPage, yourCases, { ...yourCaseDetails, route: yourCaseDetails.route.replace(":id", ":caseId") }, {name: "Nowa faktura"}]
    },
    yourCaseCorrectionInvoiceAddForm: {
        route: "/yourCases/:caseId/invoice/:invoiceId/correction",
        breadcrumbEntries: [mainPage, yourCases, { ...yourCaseDetails, route: yourCaseDetails.route.replace(":id", ":caseId") }, yourCaseInvoiceDetails, {name: "Nowa faktura korygująca"}]
    },
    yourCaseInvoiceDetails,
    yourCaseInvoiceEditForm: {
        route: "/yourCases/:caseId/invoice/:invoiceId/edit",
        breadcrumbEntries: [mainPage, yourCases, { ...yourCaseDetails, route: yourCaseDetails.route.replace(":id", ":caseId") }, {name: "Edytuj fakturę"}]
    },
    managerClientCaseInvoiceAddForm: {
        route: "/managerClients/:clientId/cases/:caseId/invoice",
        breadcrumbEntries: [mainPage, managerClientsCases, { ...managerClientCase, route: managerClientCase.route.replace(":id", ":caseId") }, {name: "Nowa faktura"}]
    },
    managerClientCaseCorrectionInvoiceAddForm: {
        route: "/managerClients/:clientId/cases/:caseId/invoice/:invoiceId/correction",
        breadcrumbEntries: [mainPage, managerClientsCases, { ...managerClientCase, route: managerClientCase.route.replace(":id", ":caseId") }, managerClientCaseInvoiceDetails, {name: "Nowa faktura"}]
    },
    managerClientCaseInvoiceDetails,
    managerClientCaseInvoiceEditForm: {
        route: "/managerClients/:clientId/cases/:caseId/invoice/:invoiceId/edit",
        breadcrumbEntries: [mainPage, managerClientsCases, { ...managerClientCase, route: managerClientCase.route.replace(":id", ":caseId") }, {name: "Edytuj fakturę"}]
    },
    yourExpertCaseInvoiceAddForm: {
        route: "/yourExpertEmployees/cases/:caseId/invoice",
        breadcrumbEntries: [mainPage, expertEmployeesCases, { ...yourExpertEmployeesCasesDetails, route: yourExpertEmployeesCasesDetails.route.replace(":id", ":caseId") }, {name: "Nowa faktura"}]
    },
    yourExpertCaseCorrectionInvoiceAddForm: {
        route: "/yourExpertEmployees/cases/:caseId/invoice/:invoiceId/correction",
        breadcrumbEntries: [mainPage, expertEmployeesCases, { ...yourExpertEmployeesCasesDetails, route: yourExpertEmployeesCasesDetails.route.replace(":id", ":caseId") }, yourExpertCaseInvoiceDetails, {name: "Nowa faktura"}]
    },
    yourExpertCaseInvoiceDetails,
    yourExpertCaseInvoiceEditForm: {
        route: "/yourExpertEmployees/cases/:caseId/invoice/:invoiceId/edit",
        breadcrumbEntries: [mainPage, expertEmployeesCases, { ...yourExpertEmployeesCasesDetails, route: yourExpertEmployeesCasesDetails.route.replace(":id", ":caseId") }, {name: "Edytuj fakturę"}]
    },
    expertCaseInvoiceAddForm: {
        route: "/expertCases/:caseId/invoice",
        breadcrumbEntries: [mainPage, expertCases, { ...expertCaseDetails, route: expertCaseDetails.route.replace(":id", ":caseId") }, {name: "Nowa faktura"}]
    },
    expertCaseCorrectionInvoiceAddForm: {
        route: "/expertCases/:caseId/invoice/:invoiceId/correction",
        breadcrumbEntries: [mainPage, expertCases, { ...expertCaseDetails, route: expertCaseDetails.route.replace(":id", ":caseId") }, expertCaseInvoiceDetails, {name: "Nowa faktura"}]
    },
    expertCaseInvoiceDetails,
    expertCaseInvoiceEditForm: {
        route: "/expertCases/:caseId/invoice/:invoiceId/edit",
        breadcrumbEntries: [mainPage, expertCases, { ...expertCaseDetails, route: expertCaseDetails.route.replace(":id", ":caseId") }, {name: "Edytuj fakturę"}]
    },
    managerCaseInvoiceAddForm: {
        route: "/managerClientsCases/:caseId/invoice",
        breadcrumbEntries: [mainPage, managerClientsCases, { ...managerClientCaseDetails, route: managerClientCaseDetails.route.replace(":id", ":caseId") }, {name: "Nowa faktura"}]
    },
    managerCaseCorrectionInvoiceAddForm: {
        route: "/managerClientsCases/:caseId/invoice/:invoiceId/correction",
        breadcrumbEntries: [mainPage, managerClientsCases, { ...managerClientCaseDetails, route: managerClientCaseDetails.route.replace(":id", ":caseId") }, managerCaseInvoiceDetails, {name: "Nowa faktura"}]
    },
    managerCaseInvoiceDetails,
    managerCaseInvoiceEditForm: {
        route: "/managerClientsCases/:caseId/invoice/:invoiceId/edit",
        breadcrumbEntries: [mainPage, managerClientsCases, { ...managerClientCaseDetails, route: managerClientCaseDetails.route.replace(":id", ":caseId") }, {name: "Edytuj fakturę"}]
    },
    userCalendar: {
        route: "/calendar/user/:userId",
        breadcrumbEntries: [mainPage, managerClients, usersProfile, {name: "Kalendarz użytkownika"}]
    },
    calendarEventTable: {route: "/calendarEvents", breadcrumbEntries: [mainPage, calendar, {name: "Wydarzenia"}]},
    calendarEventAdd: {route: "/calendarEvent/create", breadcrumbEntries: [mainPage, calendar, {name: "Wydarzenia"}]},
    calendarEventCaseAdd: {
        route: "/calendarEvent/create/case/:caseId",
        breadcrumbEntries: [mainPage, calendar, {name: "Wydarzenia w sprawie"}]
    },
    calendarEventUserAdd: {
        route: "/calendarEvent/create/user/:userId",
        breadcrumbEntries: [mainPage, managerClients, {name: "Wydarzenia użytkownika"}]
    },
    calendarEventEdit: {
        route: "/calendarEvent/:id/edit",
        breadcrumbEntries: [mainPage, calendar, {name: "Wydarzenia"}]
    },
    rankings: kpis,
    yourCases,
    expertCases,
    managerClientsCases,
    allCases,
    users,
    managerClients,
    mainPage,
    yourProfile,
    news,
    userNews,
    expertEmployees,
    expertEmployeesCases,
    people
};

const loadingIcon = <LoadingOutlined className={styles.loadingIcon} spin/>;
export const loadingSpin = <Spin className={styles.loadingContainer} indicator={loadingIcon}/>

export default function RoutingContainer() {
    return <Routes>
        <Route path={RoutingPaths.caseCorrectionInvoiceAddForm.route} element={<Pages.CorrectionInvoiceForm />} />
        <Route path={RoutingPaths.caseInvoiceAddForm.route} element={<Pages.InvoiceForm />} />
        <Route path={RoutingPaths.caseInvoiceDetails.route} element={<Pages.InvoiceDetails />} />
        <Route path={RoutingPaths.caseInvoiceEditForm.route} element={<Pages.InvoiceForm />} />
        <Route path={RoutingPaths.yourCaseCorrectionInvoiceAddForm.route} element={<Pages.CorrectionInvoiceForm />} />
        <Route path={RoutingPaths.yourCaseInvoiceAddForm.route} element={<Pages.InvoiceForm />} />
        <Route path={RoutingPaths.yourCaseInvoiceDetails.route} element={<Pages.InvoiceDetails />} />
        <Route path={RoutingPaths.yourCaseInvoiceEditForm.route} element={<Pages.InvoiceForm />} />
        <Route path={RoutingPaths.managerClientCaseCorrectionInvoiceAddForm.route} element={<Pages.CorrectionInvoiceForm />} />
        <Route path={RoutingPaths.managerClientCaseInvoiceAddForm.route} element={<Pages.InvoiceForm />} />
        <Route path={RoutingPaths.managerClientCaseInvoiceDetails.route} element={<Pages.InvoiceDetails />} />
        <Route path={RoutingPaths.managerClientCaseInvoiceEditForm.route} element={<Pages.InvoiceForm />} />
        <Route path={RoutingPaths.yourExpertCaseCorrectionInvoiceAddForm.route} element={<Pages.CorrectionInvoiceForm />} />
        <Route path={RoutingPaths.yourExpertCaseInvoiceAddForm.route} element={<Pages.InvoiceForm />} />
        <Route path={RoutingPaths.yourExpertCaseInvoiceDetails.route} element={<Pages.InvoiceDetails />} />
        <Route path={RoutingPaths.yourExpertCaseInvoiceEditForm.route} element={<Pages.InvoiceForm />} />
        <Route path={RoutingPaths.expertCaseCorrectionInvoiceAddForm.route} element={<Pages.CorrectionInvoiceForm />} />
        <Route path={RoutingPaths.expertCaseInvoiceAddForm.route} element={<Pages.InvoiceForm />} />
        <Route path={RoutingPaths.expertCaseInvoiceDetails.route} element={<Pages.InvoiceDetails />} />
        <Route path={RoutingPaths.expertCaseInvoiceEditForm.route} element={<Pages.InvoiceForm />} />
        <Route path={RoutingPaths.managerCaseCorrectionInvoiceAddForm.route} element={<Pages.CorrectionInvoiceForm />} />
        <Route path={RoutingPaths.managerCaseInvoiceAddForm.route} element={<Pages.InvoiceForm />} />
        <Route path={RoutingPaths.managerCaseInvoiceDetails.route} element={<Pages.InvoiceDetails />} />
        <Route path={RoutingPaths.managerCaseInvoiceEditForm.route} element={<Pages.InvoiceForm />} />
        <Route path={RoutingPaths.accountingAttachmentTypeAdd.route} element={<Pages.AccountingAttachmentTypeFormPage />} />
        <Route path={RoutingPaths.accountingAttachmentTypeEdit.route} element={<Pages.AccountingAttachmentTypeFormPage />} />
        <Route path={RoutingPaths.accountingAttachmentTypeTable.route} element={<Pages.AccountingAttachmentTypePage />} />
        <Route path={RoutingPaths.departmentTable.route} element={<Pages.DepartmentPage />} />
        <Route path={RoutingPaths.leadSourceTable.route} element={<Pages.LeadSourcePage />} />
        <Route path={RoutingPaths.marketingCampaignTable.route} element={<Pages.MarketingCampaignPage />} />
        <Route path={RoutingPaths.subscriptionDefinitionTable.route} element={<Pages.SubscriptionDefinitionPage />} />
        <Route path={RoutingPaths.organisationUserTable.route} element={<Pages.OrganisationUserPage/>}/>
        <Route path={RoutingPaths.organisationTable.route} element={<Pages.OrganisationPage/>}/>
        <Route path={RoutingPaths.calendarEventAdd.route} element={<Pages.CalendarEventFormPage/>}/>
        <Route path={RoutingPaths.calendarEventCaseAdd.route} element={<Pages.CalendarEventFormPage/>}/>
        <Route path={RoutingPaths.calendarEventUserAdd.route} element={<Pages.CalendarEventFormPage/>}/>
        <Route path={RoutingPaths.calendarEventEdit.route} element={<Pages.CalendarEventFormPage/>}/>
        <Route path={RoutingPaths.calendarEventTable.route} element={<Pages.CalendarEventPage/>}/>
        <Route path={RoutingPaths.rankings.route} element={<Pages.RankingsPage/>}/>
        <Route path={RoutingPaths.managerRanking.route} element={<Pages.ManagerRankingPage/>}/>
        <Route path={RoutingPaths.departmentRanking.route} element={<Pages.DepartmentRankingPage/>}/>
        <Route path={RoutingPaths.clientRanking.route} element={<Pages.ClientRankingPage/>}/>
        <Route path={RoutingPaths.expertRanking.route} element={<Pages.ExpertRankingPage/>}/>
        <Route path={RoutingPaths.ambassadorRanking.route} element={<Pages.AmbassadorRankingPage/>}/>

        <Route path={RoutingPaths.kpiEngine.route} element={<Pages.KpiEnginePage/>}/>
        <Route path={RoutingPaths.kpiSubscriptions.route} element={<Pages.KpiSubscriptionsPage/>}/>
        <Route path={RoutingPaths.kpiSalesman.route} element={<Pages.KpiSalesmanPage/>}/>
        <Route path={RoutingPaths.kpiInvestPitch.route} element={<Pages.KpiInvestPitchTable/>}/>
        <Route path={RoutingPaths.kpiInvestInProgress.route} element={<Pages.KpiInvestInProgressTable/>}/>
        <Route path={RoutingPaths.kpiTaxInProgress.route} element={<Pages.KpiTaxInProgressTable/>}/>
        <Route path={RoutingPaths.kpiTaxDone.route} element={<Pages.KpiTaxDoneTable/>}/>
        <Route path={RoutingPaths.kpiTaxNew.route} element={<Pages.KpiTaxNewTable/>}/>
        <Route path={RoutingPaths.kpiMarketingNew.route} element={<Pages.KpiMarketingNewTable/>}/>
        <Route path={RoutingPaths.kpiMarketingInProgress.route} element={<Pages.KpiMarketingInProgressTable/>}/>
        <Route path={RoutingPaths.kpiMarketingDone.route} element={<Pages.KpiMarketingDoneTable/>}/>
        <Route path={RoutingPaths.kpiOverdue.route} element={<Pages.KpiOverdueTable/>}/>
        <Route path={RoutingPaths.kpiNewBusinessMeeting.route} element={<Pages.KpiNewBusinessMeetingTable/>}/>
        <Route path={RoutingPaths.kpiServiceMeeting.route} element={<Pages.KpiServiceMeetingTable/>}/>
        <Route path={RoutingPaths.kpiPurgatory.route} element={<Pages.KpiPurgatoryTable/>}/>
        <Route path={RoutingPaths.kpiManagers.route} element={<Pages.KpiManagersTable/>}/>
        <Route path={RoutingPaths.kpiNewBusiness.route} element={<Pages.KpiNewBusinessTable/>}/>
        <Route path={RoutingPaths.kpiRecruitment.route} element={<Pages.KpiRecruitmentTable/>}/>
        <Route path={RoutingPaths.kpiChf.route} element={<Pages.KpiChfTable/>}/>
        <Route path={RoutingPaths.kpiHell.route} element={<Pages.KpiHellTable/>}/>
        <Route path={RoutingPaths.kpiCompliance.route} element={<Pages.KpiComplianceTable/>}/>
        <Route path={RoutingPaths.kpiInvestSales.route} element={<Pages.KpiInvestSalesTable/>}/>
        <Route path={RoutingPaths.kpiClientActivity.route} element={<Pages.KpiClientActivityTable/>}/>
        <Route path={RoutingPaths.kpiTaxGroup.route} element={<Pages.KpiTaxGroup/>}/>
        <Route path={RoutingPaths.kpiLawGroup.route} element={<Pages.KpiLawGroup/>}/>
        <Route path={RoutingPaths.kpiMarketingGroup.route} element={<Pages.KpiMarketingGroup/>}/>
        <Route path={RoutingPaths.kpiRecruitmentGroup.route} element={<Pages.KpiRecruitmentGroup/>}/>
        <Route path={RoutingPaths.kpiLegal.route} element={<Pages.KpiLegalTable/>}/>
        <Route path={RoutingPaths.kpiLeads.route} element={<Pages.KpiLeadsTable/>}/>
        <Route path={RoutingPaths.kpiLawSettlements.route} element={<Pages.KpiLawSettlementsTable/>}/>
        <Route path={RoutingPaths.kpiTaxSettlements.route} element={<Pages.KpiTaxSettlementsTable/>}/>
        <Route path={RoutingPaths.kpiExpertSettlementsGroup.route} element={<Pages.KpiExpertSettlementsGroup/>}/>

        <Route path={RoutingPaths.patronClientsCasesAdd.route} element={<Pages.YourClientsCaseAddPage/>}/>
        <Route path={RoutingPaths.patronClientsCasesEdit.route} element={<Pages.YourClientsCaseAddPage/>}/>
        <Route path={RoutingPaths.patronClientCaseDetails.route} element={<Pages.CaseDetailsPage/>}/>
        <Route path={RoutingPaths.patronClientsCases.route} element={<Pages.PatronClientsCasesPage/>}/>
        <Route path={RoutingPaths.patronClientCases.route} element={<Pages.ClientCasesPage/>}/>
        <Route path={RoutingPaths.patronClientCase.route} element={<Pages.CaseDetailsPage/>}/>
        <Route path={RoutingPaths.patronClients.route} element={<Pages.PatronClientsPage/>}/>
        <Route path={RoutingPaths.patronClientsProfile.route} element={<Pages.ClientProfilePage/>}/>
        <Route path={RoutingPaths.patronClientsCreate.route} element={<Pages.CreateClientPage/>}/>
        <Route path={RoutingPaths.patronClientsEdit.route} element={<Pages.CreateClientPage/>}/>
        <Route path={RoutingPaths.yourPatron.route} element={<Pages.YourPatronPage/>}/>
        <Route path={RoutingPaths.accountingTypeAdd.route} element={<Pages.AccountingTypeFormPage/>}/>
        <Route path={RoutingPaths.accountingTypeEdit.route} element={<Pages.AccountingTypeFormPage/>}/>
        <Route path={RoutingPaths.accountingTypeTable.route} element={<Pages.AccountingTypePage/>}/>

        <Route path={RoutingPaths.settlementTable.route} element={<Pages.SettlementPage/>}/>
        <Route path={RoutingPaths.overdueSettlements.route} element={<Pages.OverdueSettlementsPage/>}/>
        <Route path={RoutingPaths.mySettlementsTable.route} element={<Pages.MySettlementsPage/>}/>
        <Route path={RoutingPaths.clientSettlementsTable.route} element={<Pages.ClientSettlementsPage/>}/>
        <Route path={RoutingPaths.incomeTable.route} element={<Pages.IncomeSettlementsPage/>}/>

        <Route path={RoutingPaths.productAdd.route} element={<Pages.ProductFormPage/>}/>
        <Route path={RoutingPaths.productEdit.route} element={<Pages.ProductFormPage/>}/>
        <Route path={RoutingPaths.milestoneEdit.route} element={<Pages.ProductFormPage/>}/>
        <Route path={RoutingPaths.productTable.route} element={<Pages.ProductPage/>}/>

        <Route path={RoutingPaths.login.route} element={<Pages.LoginPage/>}/>

        <Route path={RoutingPaths.register.route} element={<Pages.RegisterPage/>}/>

        <Route path={RoutingPaths.forgotPasswordComplete.route} element={<Pages.ForgotPasswordCompletePage/>}/>

        <Route path={RoutingPaths.activate.route} element={<Pages.ActivatePage/>}/>

        <Route path={RoutingPaths.forgotPassword.route} element={<Pages.ForgotPasswordPage/>}/>

        <Route path={RoutingPaths.professions.route} element={<Pages.ProfessionListPage/>}/>

        <Route path={RoutingPaths.managerTitles.route} element={<Pages.ManagerTitleListPage/>}/>

        <Route path={RoutingPaths.expertEmployees.route} element={<Pages.ExpertEmployeesPage/>}/>

        <Route path={RoutingPaths.expertEmployeesCreate.route} element={<Pages.ExpertEmployeesCreatePage/>}/>

        <Route path={RoutingPaths.expertEmployeesProfile.route} element={<Pages.ExpertEmployeesProfilePage/>}/>

        <Route path={RoutingPaths.expertEmployeesCases.route} element={<Pages.ExpertEmployeesCasesPage/>}/>

        <Route path={RoutingPaths.yourExpertEmployeesCasesDetails.route}
               element={<Pages.ExpertEmployeesCasesDetails/>}/>

        <Route path={RoutingPaths.news.route} element={<Pages.NewsListPage/>}/>

        <Route path={RoutingPaths.userNews.route} element={<Pages.UserNewsListPage/>}/>

        <Route path={RoutingPaths.userNewsDetails.route} element={<Pages.UserNewsDetailsPage/>}/>

        <Route path={RoutingPaths.createNews.route} element={<Pages.CreateNewsPage/>}/>

        <Route path={RoutingPaths.editNews.route} element={<Pages.CreateNewsPage/>}/>

        <Route path={RoutingPaths.users.route} element={<Pages.UsersPage/>}/>

        <Route path={RoutingPaths.usersProfile.route} element={<Pages.UsersProfilePage/>}/>

        <Route path={RoutingPaths.usersCreate.route} element={<Pages.CreateUserPage/>}/>

        <Route path={RoutingPaths.usersEdit.route} element={<Pages.CreateUserPage/>}/>

        <Route path={RoutingPaths.managerClients.route} element={<Pages.ManagerClientsPage/>}/>

        <Route path={RoutingPaths.managerClientsProfile.route} element={<Pages.ClientProfilePage/>}/>

        <Route path={RoutingPaths.managerClientsCreate.route} element={<Pages.CreateClientPage/>}/>

        <Route path={RoutingPaths.managerClientsEdit.route} element={<Pages.CreateClientPage/>}/>

        <Route path={RoutingPaths.mainPage.route} element={<Pages.MainPage/>}/>

        <Route path={RoutingPaths.confirmDetails.route} element={<Pages.ConfirmDetailsPage/>}/>

        <Route path={RoutingPaths.clientExpertEmployees.route} element={<Pages.ClientExpertEmployeesPage/>}/>

        <Route path={RoutingPaths.clientExpertEmployeesDetails.route}
               element={<Pages.ClientExpertEmployeesDetailsPage/>}/>

        <Route path={RoutingPaths.clientManagerDetails.route}
               element={<Pages.ClientManagerDetailsPage/>}/>

        <Route path={RoutingPaths.editProfile.route} element={<Pages.EditProfilePage/>}/>

        <Route path={RoutingPaths.changePassword.route} element={<Pages.ChangePasswordPage/>}/>

        <Route path={RoutingPaths.yourProfile.route} element={<Pages.YourProfilePage/>}/>

        <Route path={RoutingPaths.yourCases.route} element={<Pages.YourCasesPage/>}/>

        <Route path={RoutingPaths.expertCases.route} element={<Pages.YourCasesExpertPage/>}/>

        <Route path={RoutingPaths.expertCaseDetails.route} element={<Pages.ExpertCaseDetails/>}/>

        <Route path={RoutingPaths.casesAdd.route} element={<Pages.CaseAddPage/>}/>

        <Route path={RoutingPaths.managerClientsCasesAdd.route} element={<Pages.YourClientsCaseAddPage/>}/>

        <Route path={RoutingPaths.clientCasesAdd.route} element={<Pages.CaseAddPage/>}/>

        <Route path={RoutingPaths.managerClientCasesAdd.route} element={<Pages.YourClientsCaseAddPage/>}/>

        <Route path={RoutingPaths.casesEdit.route} element={<Pages.CaseAddPage/>}/>

        <Route path={RoutingPaths.managerClientsCasesEdit.route} element={<Pages.YourClientsCaseAddPage/>}/>

        <Route path={RoutingPaths.caseDetails.route} element={<Pages.CaseDetailsPage/>}/>

        <Route path={RoutingPaths.yourCaseDetails.route} element={<Pages.CaseDetailsClientPage/>}/>

        <Route path={RoutingPaths.managerClientCaseDetails.route} element={<Pages.CaseDetailsPage/>}/>

        <Route path={RoutingPaths.allCases.route} element={<Pages.AllCasesPage/>}/>

        <Route path={RoutingPaths.managerClientsCases.route} element={<Pages.ManagerClientsCasesPage/>}/>

        <Route path={RoutingPaths.managerClientCases.route} element={<Pages.ClientCasesPage/>}/>

        <Route path={RoutingPaths.managerClientCase.route} element={<Pages.CaseDetailsPage/>}/>

        <Route path={RoutingPaths.userCases.route} element={<Pages.UserCasesPage/>}/>

        <Route path={RoutingPaths.yourCaseAdd.route} element={<Pages.YourCaseAddPage/>}/>

        <Route path={RoutingPaths.people.route} element={<Pages.PeoplePage/>}/>

        <Route path={RoutingPaths.managerAttachments.route} element={<Pages.ManagerAttachmentsPage/>}/>

        <Route path={RoutingPaths.calendar.route} element={<Pages.CalendarPage/>}/>
        <Route path={RoutingPaths.caseCalendar.route} element={<Pages.CalendarPage/>}/>
        <Route path={RoutingPaths.userCalendar.route} element={<Pages.CalendarPage/>}/>
    </Routes>
}
