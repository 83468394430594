import { ApplicationUserRole } from "../api";

export function getRoleName(role: ApplicationUserRole): string {
    switch (role) {
        case ApplicationUserRole.Admin:
            return "administrator";
        case ApplicationUserRole.Client:
            return "klient";
        case ApplicationUserRole.ExpertEmployee:
            return "ekspert-pracownik";
        case ApplicationUserRole.ExpertOwner:
            return "ekspert-właściciel";
        case ApplicationUserRole.Manager:
            return "manager";
        case ApplicationUserRole.Ambassador:
            return "ambasador";
        case ApplicationUserRole.Patron:
            return "opiekun";
    }
}

export function getSimpleRoleName(role: ApplicationUserRole): string {
    switch (role) {
        case ApplicationUserRole.Admin:
            return "administrator";
        case ApplicationUserRole.Client:
            return "klient";
        case ApplicationUserRole.ExpertEmployee:
            case ApplicationUserRole.ExpertOwner:
            return "ekspert";
        case ApplicationUserRole.Manager:
            return "manager";
        case ApplicationUserRole.Ambassador:
            return "ambasador";
        case ApplicationUserRole.Patron:
            return "opiekun";
    }
}

export function getRoleOrder(role: ApplicationUserRole): number {
    switch (role) {
        case ApplicationUserRole.Client:
            return 1;
        case ApplicationUserRole.Patron:
            return 2;
        case ApplicationUserRole.Manager:
            return 3;
        case ApplicationUserRole.Ambassador:
            return 4;
        case ApplicationUserRole.ExpertEmployee:
            return 5;
        case ApplicationUserRole.ExpertOwner:
            return 6;
        case ApplicationUserRole.Admin:
            return 7;
    }
}

export function isGweEmployee(roles: ApplicationUserRole[]): boolean {
    const gweRoles = [
        ApplicationUserRole.Manager,
        ApplicationUserRole.Admin
    ];
    return roles.some(role => gweRoles.includes(role));
}